import React, { useState, useEffect, useRef } from "react";
import { CContainer, CRow, CCol } from "@coreui/react";
import { CFormInput, CForm, CFormLabel, CButton } from "@coreui/react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Spinner from "./loader/Spinner";
import "./loader/Spinner.css";
import logo from "../assets/images/logo.png";
import { gapi } from "gapi-script";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import AppleLogin from "react-apple-login";
import { Helmet } from "react-helmet";

function Login() {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Login | cinewoo",
    description:
      "Log in to cinewoo to explore casting opportunities, pitch films, access the video hub, and enjoy our exclusive cinematheque - - all tailored to help you connect, interact and discover.",
    url: `${window.location.origin}/login`,
    breadcrumb: {
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: `${window.location.origin}/`,
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Login",
          item: `${window.location.origin}/login`,
        },
      ],
    },
  };

  const recaptcha = useRef();
  const [blocking, setBlocking] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const production = process.env.REACT_APP_ENV;
  const recaptchaSiteKey = process.env.REACT_APP_SITE_KEY;
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState([]);

  const handleCheckboxChange = (e) => {
    setRemember(e.target.checked);
    console.log("remember : ", remember);
  };

  // Set the redirect URL based on the environment
  const redirectUrl =
    production === "production"
      ? "https://cinewoo.com"
      : "https://frontend.cinewoo.com";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          responseGoogle(res);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  // for google login
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "753595080102-h6sj78cc13i0oe0h4hulnb3bs5r5rqj6.apps.googleusercontent.com",
        scope: "email",
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  // for login
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    // const captchaValue = recaptcha.current.getValue()
    // if (!captchaValue) {
    //   toast.error(t('Please verify the reCAPTCHA!'));
    //   return;
    // }

    if (!validateEmail(email)) {
      toast.error(t("Enter a valid email address"));
      return;
    }

    if (!email || !password) {
      toast.error(t("Please enter both email and password"));
      return;
    }

    setLoading(true);
    setBlocking(true);

    try {
      const language = localStorage.getItem("language");
      const response = await axios.post(
        `${apiUrl}/login`,
        {
          email: email,
          password: password,
          rememberMe: remember,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "accept-language": language,
          },
        }
      );

      // console.log(response.data);

      if (response.data.result === "success") {
        setBlocking(false);
        console.log("Login successful");
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("userid", response.data.data.id);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        localStorage.setItem(
          "profileComplete",
          response.data.data.profile_complete
        );
        toast.success(t("Login successful!"));

        const timeout = setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("userid");
        }, 1800000);

        const clearTokenTimeout = () => {
          clearTimeout(timeout);
        };

        // navigate(/edit-user-profile);
        const from = location.state?.from || "/edit-user-profile";
        navigate(from);
        return clearTokenTimeout;
      } else if (response.data.result === "error") {
        console.log("response verified:  ", response.data.data.email_verified);
        if (!response?.data?.data?.email_verified) {
          navigate("/verify-email");
        }
      } else {
        setBlocking(false);
        toast.error(response?.data?.message);
        // if (!response?.data?.data?.email_verified) {
        //   navigate('/verify-email');
        // }
      }
    } catch (error) {
      setBlocking(false);
      console.error("Error logging in:", error.message);
      if (error.response) {
        if (error.response.data.message === "Invalid Credentials") {
          toast.error("Invalid Credentials");
        } else {
          toast.error(error.response.data.message);
          if (
            !error.response?.data?.email_verified &&
            error.response?.data?.message ===
              "Your account is not verified. Please verify your email."
          ) {
            navigate("/verify-email");
            console.log("enter condition");
          }
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setBlocking(false);
      setLoading(false);
    }
  };

  const responseGoogle = (response) => {
    setBlocking(true);
    if (response.error) {
      console.error("Google Login Error:", response.error);
      toast.error("Google Login Error: " + response.error);
      setBlocking(false);
      return;
    }

    const { data } = response;
    const body = {
      name: data.name,
      email: data.email,
      provider: "gmail",
      provider_id: data.id,
    };

    fetch(`${apiUrl}/social-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (!res.ok) {
          setBlocking(false);
          throw new Error("Network response was not ok " + res.statusText);
        }
        return res.json();
      })
      .then((data) => {
        const userId = data.data.id;
        const tokens = data.data.token;
        if (userId) {
          localStorage.setItem("user", JSON.stringify(data.data));
          localStorage.setItem("userid", userId);
          localStorage.setItem("token", tokens);
          localStorage.setItem("profileComplete", data.data.profile_complete);
          // if(!data.data.email_verified){
          //   navigate('/verify-email');
          //   return;
          // }
          toast.success("User logged in successfully!");
          setBlocking(false);
          navigate("/settings");
        } else {
          setBlocking(false);
          console.error("Google login failed");
        }
      })
      .catch((error) => {
        setBlocking(false);
        toast.error("Google login failed: " + error.message);
      });
  };

  const signIn = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  // const { signIn } = useGoogleLogin({
  //   onSuccess: responseGoogle,
  //   onFailure: responseGoogle,
  //   clientId: "753595080102-h6sj78cc13i0oe0h4hulnb3bs5r5rqj6.apps.googleusercontent.com",
  //   scope: "email",
  // });

  return (
    <>
      {/* <Helmet>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet> */}
      <Helmet>
        {/* Dynamic Title and Description */}
        <title>Login | cinewoo</title>
        <meta
          name="description"
          content="Log in to cinewoo to explore casting opportunities, pitch films, access the video hub, and enjoy our exclusive cinematheque - - all tailored to help you connect, interact and discover. "
        />
        <meta property="og:title" content="Login | cinewoo" />
        <meta
          property="og:description"
          content="Log in to cinewoo to explore casting opportunities, pitch films, access the video hub, and enjoy our exclusive cinematheque - - all tailored to help you connect, interact and discover."
        />
        <meta property="og:url" content={`${window.location.origin}/login`} />
        <meta property="og:image" content={"https://cinewoo.com/logo.png"} />
        <meta property="og:type" content="login" />
        <meta property="og:site_name" content="Cinewoo" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <BlockUi tag="div" blocking={blocking}>
        <section className="auth-section-wrapper login-wrapper">
          <CContainer className="h-100-p">
            <CRow className="align-items-center h-100-p">
              <CCol md="7">
                <div className="site-logo">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
              </CCol>
              <CCol md="5">
                <div className="auth-form-wrapper">
                  <div className="app-logo-wrapper">
                    <h2>{t("LOGIN")}</h2>
                  </div>
                  <div className="auth-form-wrapper-content">
                    <CForm>
                      <div className="single-input-wrapper">
                        <CFormLabel className="d-block">
                          {t("Email")}
                        </CFormLabel>
                        <CFormInput
                          type="email"
                          placeholder={t("Email")}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="single-input-wrapper mt-4">
                        <CFormLabel className="d-block">
                          {t("Password")}
                        </CFormLabel>
                        <CFormInput
                          type="password"
                          placeholder={t("Enter Password")}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-between flex-wrap mt-3">
                        <div className="single-check-input-wrapper">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="flexCheckDefault"
                              checked={remember}
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              {t("Remember Me")}
                            </label>
                          </div>
                        </div>

                        <div className="register-link-wrapper">
                          <p className="mb-0">
                            <Link to="/forgot-password">
                              {t("Forgot Password")}?
                            </Link>
                          </p>
                        </div>
                      </div>

                      <div className="login-btn-wrapper my-4">
                        <CButton
                          type="submit"
                          color="waring"
                          onClick={handleLogin}
                          disabled={loading}
                        >
                          {loading ? <Spinner /> : <>{t("LOGIN")}</>}
                        </CButton>
                      </div>
                    </CForm>
                  </div>
                  <div className="auth-footer-wrapper mb-5">
                    <div className="register-link-wrapper">
                      <p>
                        {t("Don’t have an account")}?{" "}
                        <Link to="/signup">{t("Sign up here")}</Link>
                      </p>
                    </div>
                    <div className="social-login">
                      <p>{t("OR")}</p>
                      <p>{t("Login with")}</p>
                    </div>
                    <div className="social-login-links">
                      {/* login with google */}

                      <button
                        onClick={() => signIn()}
                        style={{
                          background: "none",
                          border: "none",
                          padding: 0,
                        }}
                      >
                        <svg
                          width="44"
                          height="44"
                          viewBox="0 0 44 44"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            style={{ mixBlendMode: "luminosity" }}
                            clipPath="url(#clip0_2014_5040)"
                          >
                            <path
                              d="M36.1537 38.7321C32.3381 41.9548 27.3882 44.0001 21.9998 44.0001C13.9818 44.0001 6.94347 39.5829 3.12793 33.1376L4.55638 26.5529L10.8881 25.3774C12.3576 30.1211 16.7919 33.6017 21.9998 33.6017C24.5263 33.6017 26.8725 32.8024 28.8061 31.3845L34.8904 32.3126L36.1537 38.7321Z"
                              fill="#59C36A"
                            />
                            <path
                              d="M36.1539 38.7319L34.8906 32.3124L28.8063 31.3843C26.8727 32.8022 24.5265 33.6015 22 33.6015V43.9999C27.3884 43.9999 32.3383 41.9546 36.1539 38.7319Z"
                              fill="#00A66C"
                            />
                            <path
                              d="M10.3984 21.9998C10.3984 23.1857 10.5789 24.32 10.8883 25.3771L3.12813 33.1373C1.19453 29.8889 0 26.0732 0 21.9998C0 17.9263 1.19453 14.1107 3.12813 10.8623L9.35627 11.9342L10.8883 18.6225C10.5789 19.6794 10.3984 20.8138 10.3984 21.9998Z"
                              fill="#FFDA2D"
                            />
                            <path
                              d="M44 22C44 28.703 40.8977 34.7102 36.1539 38.732L28.8063 31.3844C30.3015 30.3015 31.5648 28.8577 32.3898 27.1562H22C21.278 27.1562 20.7109 26.589 20.7109 25.8672V18.1328C20.7109 17.4109 21.278 16.8438 22 16.8438H42.35C42.9687 16.8438 43.5102 17.2819 43.6133 17.9007C43.8711 19.2414 44 20.6335 44 22Z"
                              fill="#4086F4"
                            />
                            <path
                              d="M32.3899 27.1562C31.5649 28.8577 30.3015 30.3015 28.8063 31.3844L36.1539 38.732C40.8977 34.7102 44 28.7031 44 22C44 20.6335 43.8711 19.2414 43.6132 17.9007C43.5102 17.2819 42.9687 16.8438 42.3499 16.8438H22V27.1562H32.3899Z"
                              fill="#4175DF"
                            />
                            <path
                              d="M36.5146 6.14445C36.5404 6.50538 36.3857 6.84062 36.1537 7.09835L30.6365 12.5898C30.1983 13.0538 29.4765 13.1054 28.9608 12.7187C26.9239 11.1977 24.5263 10.3984 21.9998 10.3984C16.7919 10.3984 12.3576 13.8788 10.8881 18.6227L3.12793 10.8625C6.94347 4.41719 13.9818 0 21.9998 0C27.1303 0 32.1318 1.89062 36.0506 5.19054C36.3342 5.42266 36.4889 5.78351 36.5146 6.14445Z"
                              fill="#FF641A"
                            />
                            <path
                              d="M28.9609 12.7187C29.4766 13.1055 30.1984 13.0538 30.6366 12.5898L36.1538 7.09835C36.3859 6.84062 36.5406 6.50547 36.5148 6.14445C36.4889 5.78342 36.3343 5.42266 36.0507 5.19054C32.132 1.89062 27.1305 0 22 0V10.3984C24.5265 10.3984 26.9241 11.1977 28.9609 12.7187Z"
                              fill="#F03800"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2014_5040">
                              <rect width="44" height="44" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CContainer>
        </section>
      </BlockUi>
    </>
  );
}

export default Login;
