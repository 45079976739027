import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import playBtn from '../assets/images/play.png';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { CModal, CModalBody, CModalFooter, CButton } from '@coreui/react';
import { useTranslation } from "react-i18next";

const SimpleVideoPlayer = ({ videoUrl, width, height, title, videoSrc, userProfile, link, btnContent, profilelink, deleteId, videoType, onDelete, videoId, userId }) => {
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const togglePlayPause = () => {
    setPlaying(!playing);
    if (!playing) setLoading(true);
  };

  const handleDelete = async () => {
    if (!videoId) {
      console.error('No media ID provided');
      return;
    }

    let endpoint = '';
    if (videoType === 'Pitching Video') {
      endpoint = `${apiUrl}/filmpitching/${videoId}`;
    } else if (videoType === 'Audition') {
      endpoint = `${apiUrl}/casting-call/auditions/${videoId}`;
    } else {
      console.error('Invalid media type');
      return;
    }

    try {
      const language = localStorage.getItem("language");
      const token = localStorage.getItem('token');
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'accept-language': language
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message);
        throw new Error(`HTTP error! status: ${response.status}, Message: ${errorData.message}`);
      }

      onDelete && onDelete(videoId);
      console.log(`${videoType} deleted successfully`);
      toast.success(`${videoType} deleted successfully`);
    } catch (error) {
      console.error(`Failed to delete the ${videoType}:`, error);
    }
  };

  const handleDownload = () => {
    saveAs(videoUrl, `${videoId}.mp4`);
  };
  
  return (
    <div className='talent-frame-wrapper profile-clip mt-4'>
      <div className='video-frame position-relative'>
        <div 
          className="video-player-container"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {hovered && deleteId &&(
            <button className='delete-icon' onClick={()=>setVisible(true)}>
              <svg width="28" height="28" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29 57.9619C45.0163 57.9619 58 44.9867 58 28.981C58 12.9752 45.0163 0 29 0C12.9837 0 0 12.9752 0 28.981C0 44.9867 12.9837 57.9619 29 57.9619Z" fill="#292929"/>
                <path d="M19.8035 15.2953L15.3186 19.7803L38.201 42.6627L42.686 38.1777L19.8035 15.2953Z" fill="white"/>
                <path d="M38.1978 15.2934L15.3154 38.1758L19.8004 42.6607L42.6828 19.7783L38.1978 15.2934Z" fill="white"/>
              </svg>
            </button>
          )}

          <ReactPlayer
            url={videoUrl}
            playing={playing}
            controls={playing}
            width={width}
            height={height}
            onPlay={() => setPlaying(true)}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload' // Prevent download
                }
              }
            }}
          />
           
          {!playing && (
            <button className="play-button m-0" onClick={() => setPlaying(true)}>
              <img src={playBtn} alt="Play Button" />
            </button>
          )}
         
        </div>
      </div>
     
      <div className="d-flex justify-content-between">
        <div className='video-desc'>
          <div className='video-title mt-lg-3 mt-2'>
            <Link 
              to={profilelink} 
              onClick={()=> localStorage.setItem('targetUserId',userId)}
            >
              {title}
            </Link>
          </div>
          <div className='video-src'>{videoSrc}</div>
        </div>
        {userProfile ? 
          <div className='video-link' style={{ width:  videoType === 'Audition'? '50%' : '30%' }}>
            <Link onClick={(e)=>handleDownload(e)} className='download-btn' title="Download">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
              </svg>
            </Link>
            <Link 
              to={link} 
              className='video-link-btn'
              onClick={()=> localStorage.setItem('targetUserId',userId)}
            >
              <button className="primary_outline_btn mt-3" 
                onClick={togglePlayPause}
              >
                {btnContent}
              </button>
            </Link>
          </div>
        :
          <></>
        }
      </div>


      {/*/--- modal to verify delete operation ---/*/}
      <CModal
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="VerticallyCenteredExample"
        className='confirmation-modal'
      >
        <CModalBody className='py-5'>
          <p className='confirmation-txt'>{t("Do you really want to delete the")} {videoType}?</p>
        </CModalBody>
        <CModalFooter>
          <CButton className='confirmation-btn secondary_outline_btn ' onClick={() => setVisible(false)}>
            {t('No')}
          </CButton>
          <CButton className='confirmation-btn primary_btn' onClick={handleDelete}>{t('Yes')}</CButton>
        </CModalFooter>
      </CModal>
    </div>
  );
};

export default SimpleVideoPlayer;
