import React from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CopyRight() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear(); 

  return (
    <>
      <section className='copy-right-wrapper'>
        <CContainer className="h-100-p">
          <CRow className="align-items-center h-100-p">
            <CCol lg={8} md={6} sm={6} xs={12} className='pe-lg-5'>
              <p className='text-md-start text-center'>
                {t('Copyright ©')} {currentYear} <Link to='/'>CINEWOO</Link> {t('STUDIOS Inc')}
              </p>
            </CCol>
            <CCol lg={4} md={6} sm={6} xs={12}>
              <p className='text-md-end text-center privacy-content'>
                <Link to='/privacy-policy'>{t('privacy policy')}</Link> & <Link to='/terms-of-use'>{t('terms of use')}</Link>
              </p>
            </CCol>
          </CRow>
        </CContainer>
      </section>
    </>
  );
}

export default CopyRight;
