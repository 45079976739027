import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import CopyRight from '../../components/CopyRight';
import { CCol, CContainer, CRow, CPagination, CPaginationItem } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import '../../assets/css/blogs.css';
import BlogsCard from '../../components/blogs/BlogsCard';
import EmptyBlog from '../../components/blogs/EmptyBlog';
import Spinner from '../../components/loader/Spinner';
import { Helmet } from 'react-helmet';

function Blog() {
    const { t } = useTranslation();
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 9;

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const imgBaseUrl = process.env.REACT_APP_IMAGE_URL;

    const fetchBlogs = async () => {
        setLoading(true);
        try {
            const language = localStorage.getItem("language");
            const response = await fetch(`${apiUrl}/blogs`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'accept-language': language
                }
            });
            const data = await response.json();
            if (response.ok) {
                setBlogs(data.data);
            } else {
                console.error('Failed to fetch blogs:', data.message);
            }
        } catch (error) {
            console.error('Error fetching blogs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogs();
    }, []);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const getDisplayedBlogs = () => {
        const startIndex = (currentPage - 1) * blogsPerPage;
        const endIndex = startIndex + blogsPerPage;
        const currentBlogs = blogs.slice(startIndex, endIndex);

        const filledBlogs = [...currentBlogs];
        // console.log(filledBlogs , 'filledblogs' , currentBlogs)
        // while (filledBlogs.length < blogsPerPage) {
        //     filledBlogs.push(null); // Add placeholders for empty blogs
        // }
       
        return filledBlogs;
    };

    const totalPages = Math.ceil(blogs.length / blogsPerPage);

    const generatePagination = () => {
        const paginationItems = [];
        if (totalPages <= 4) {
            for (let i = 1; i <= totalPages; i++) {
                paginationItems.push(
                    <CPaginationItem
                        key={i}
                        active={i === currentPage}
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </CPaginationItem>
                );
            }
        } else {
            paginationItems.push(
                <CPaginationItem
                    key={1}
                    active={1 === currentPage}
                    onClick={() => handlePageChange(1)}
                >
                    1
                </CPaginationItem>
            );
            paginationItems.push(
                <CPaginationItem
                    key={2}
                    active={2 === currentPage}
                    onClick={() => handlePageChange(2)}
                >
                    2
                </CPaginationItem>
            );

            if (currentPage > 3) {
                paginationItems.push(<CPaginationItem key="left-ellipsis" disabled>...</CPaginationItem>);
            }

            if (currentPage > 2 && currentPage < totalPages - 1) {
                paginationItems.push(
                    <CPaginationItem
                        key={currentPage}
                        active={true}
                        onClick={() => handlePageChange(currentPage)}
                    >
                        {currentPage}
                    </CPaginationItem>
                );
            }

            if (currentPage < totalPages - 2) {
                paginationItems.push(<CPaginationItem key="right-ellipsis" disabled>...</CPaginationItem>);
            }

            paginationItems.push(
                <CPaginationItem
                    key={totalPages - 1}
                    active={totalPages - 1 === currentPage}
                    onClick={() => handlePageChange(totalPages - 1)}
                >
                    {totalPages - 1}
                </CPaginationItem>
            );
            paginationItems.push(
                <CPaginationItem
                    key={totalPages}
                    active={totalPages === currentPage}
                    onClick={() => handlePageChange(totalPages)}
                >
                    {totalPages}
                </CPaginationItem>
            );
        }
        return paginationItems;
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Blog | cinewoo",
        "description": "Discover fresh perspectives and insights into the latest happenings in film.",
        "url": `${window.location.origin}/blog`,
        "publisher": {
          "@type": "Organization",
          "name": "Cinewoo",
          "url": "https://cinewoo.com",
          "logo": {
            "@type": "ImageObject",
            "url": "https://cinewoo.com/logo.png", // Replace with actual logo URL
            "width": 250,
            "height": 60
          }
        },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": `${window.location.origin}/`
            },{
                "@type": "ListItem",
                position: 2,
                name: "Blog",
                item: `${window.location.origin}/blog`,
              },
          ]
        }
      };

    return (
        <>
        <Helmet>
                {/* Dynamic Title and Description */}
                <title>Blog | cinewoo</title>
                <meta name="description" content="Discover fresh perspectives and insights into the latest happenings in film." />
                <meta property="og:title" content="Blog | cinewoo" />
                <meta property="og:description" content="Discover fresh perspectives and insights into the latest happenings in film." />
                <meta property="og:url" content={`${window.location.origin}/blog`} />
                <meta property="og:image" content={'https://cinewoo.com/logo.png'} />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="Cinewoo" />
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>

            </Helmet>
            <Navbar authorized={false} classTitle="blog-nav" />

            {/* Banner Section */}
            <section className="blog-banner-wrapper">
                <CContainer className="h-100-p">
                    <div className="blog-banner-content h-100-p">
                        <div className="blog-banner-inner-layer h-100-p">
                            <CRow className="align-items-center h-100-p">
                                <CCol lg={12}>
                                    <div className="text-center">
                                        <h1>{t('Blog')}</h1>
                                        <p>{t('Discover fresh perspectives and insights into the latest happenings in film')}</p>
                                    </div>
                                </CCol>
                            </CRow>
                        </div>
                    </div>
                </CContainer>
            </section>

            {/* Blogs Listing Section */}
            <section className="blog-listing-section">
                <CContainer>
                    <CRow>
                        {loading ? (
                            <CCol lg={12}>
                                <Spinner />
                            </CCol>
                        ) : (
                            getDisplayedBlogs().length > 0 ? getDisplayedBlogs().map((blog, index) => (
                                <CCol lg={4} md={6} key={index}>
                                    {blog && (
                                        <BlogsCard
                                            blogId={blog._id}
                                            blogSlug={blog.slug}
                                            blogImg={`${imgBaseUrl}/${blog.featureImage}`}
                                            blogWriter={blog.author}
                                            // blogPostDate={new Date(blog.createdAt).toLocaleDateString()}
                                            blogPostDate={new Date(blog.createdAt).toLocaleDateString('en-US', {
                                                month: '2-digit',
                                                day: '2-digit',
                                                year: '2-digit'
                                            })} // Format as mm/dd/yy
                                            blogTitle={blog.title}
                                            blogDetails={blog.content.replace(/<\/?[^>]+(>|$)/g, '')} // Strip HTML
                                        />
                                    )}
                                </CCol>
                            )): <p>No Article Found</p>
                        )}
                    </CRow>
                    <div className='pagination-wrapper'>
                        {/* Pagination */}
                        {totalPages > 1 && (
                            <CPagination className="justify-content-center mt-4">
                                <CPaginationItem
                                    disabled={currentPage === 1}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                >
                                    Previous
                                </CPaginationItem>
                                {generatePagination()}
                                <CPaginationItem
                                    disabled={currentPage === totalPages}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                >
                                    Next
                                </CPaginationItem>
                            </CPagination>
                        )}
                    </div>
                </CContainer>
            </section>

            <Footer />
            <CopyRight />
        </>
    );
}

export default Blog;
