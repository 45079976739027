import React, { useState, useEffect, useRef, useCallback } from "react";
import { CContainer, CRow, CCol, CButton, CForm } from "@coreui/react";
import { CTable, CTableBody, CTableRow, CTableDataCell } from "@coreui/react";
import { CModal, CModalBody, CModalFooter } from "@coreui/react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Cropper from 'react-easy-crop';
import getCroppedImg from "../components/getCroppedImg";

//-------- components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CopyRight from "../components/CopyRight";
import VideoPlayer from "../components/VideoPlayer";
// import EditUserMedia from "../components/EditUserMedia";
import EditUserMedia2 from "../components/EditUserMedia2";
import Spinner from "../components/loader/Spinner";

//---- images
import editImg from "../assets/images/edit-btn.png";
import awardIcon from "../assets/images/award-icon.svg";
import addBtn from "../assets/images/add-btn.svg";
import videoPlaceHolder from '../assets/images/profile-video-thumbnail.png';
import userAvatar from '../assets/images/profile-place.png';
import linkedInI from '../assets/images/social-icons/linkedIn.svg';
import fbI from '../assets/images/social-icons/fb.svg';
import twitterI from '../assets/images/social-icons/twitter.svg';
import discordI from '../assets/images/social-icons/discord.svg';
import whatsappI from '../assets/images/social-icons/whatsapp.svg';
import copyI from '../assets/images/social-icons/copy-icon.svg';
import shareIcon from '../assets/images/share-icon.svg';

function EditProfile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [shareVisible, setShareVisible] = useState(false);
  const [alertTxt, setAlertTxt] = useState('');
  const shareUrl = process.env.REACT_APP_SHARE_URL;


  const [croppedImages, setCroppedImages] = useState([]); // Cropped images
  const [imgSrc, setImgSrc] = useState(null); // Selected image source
  const [crop, setCrop] = useState({ x: 0, y: 0 }); // Crop position
  const [zoom, setZoom] = useState(1); // Zoom level
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null); // Cropped area

  //----- profile image cropper handling start's here

  // Function to handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImgSrc(reader.result); // Load selected image
      reader.readAsDataURL(file);
    }
  };

  // Function to handle cropping completion
  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels); // Save cropped area in pixels
  }, []);

  // Function to save cropped image
  const saveCroppedImage = async () => {
    if (!imgSrc || !croppedAreaPixels) {
      toast.error('No cropped image to save');
      return;
    }

    try {
      const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels);
      const objectUrl = URL.createObjectURL(croppedImage);

      // Save Blob and URL for preview and upload
      setCroppedImages([{ blob: croppedImage, objectUrl }]); // Overwrite instead of appending
      setProfilePicture(croppedImage);
      setImgSrc(null); // Reset selected image 
      toast.success('Image saved successfully!');
    } catch (error) {
      console.error('Error saving cropped image:', error);
      toast.error('Failed to save cropped image');
    }
  };

  //----- profile image cropper handling end's here

  useEffect(() => {
    console.log('Component mounted or updated');
    window.scrollTo(0, 0);
  }, []);

  const userid = localStorage.getItem("userid");
  const language = localStorage.getItem("language");
  const [menuListOpen, setMenuListOpen] = useState(false);
  const handleMenuList = (awardId) => {
    // setMenuListOpen(!menuListOpen);
    setMenuListOpen(prevId => (prevId === awardId ? null : awardId));
  }
  //---- modals visibility states
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [profileCropModal, setProfileCropModal] = useState(false);
  const [alertvisible, setAlertVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [rolesEdit, setRolesEdit] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [videoloading, setvideoLoading] = useState(false);
  const [awardTitle, setAwardTitle] = useState("");
  const [awardDesc, setAwardDesc] = useState("");
  const [awards, setAwards] = useState([]);
  const [awardId, setAwardId] = useState('');
  const [awardEditMode, setAwardEditMode] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);

  const [awardLoading, setAwardLoading] = useState(false);
  const isFirstRender = useRef(true);

  //----- block ui state
  const [blocking, setBlocking] = useState(false);
  //----- block ui state

  const [editInput, setEditInput] = useState(false);
  const handleEditInput = () => {
    setEditInput(!editInput);
  };

  //--- profile picture update handling
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");

  const handleFileChange = (event) => {
    console.log('image input');
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setLoading(true); // Start loading
      setProfilePicture(selectedFile); // Store the file
      const reader = new FileReader();
      console.log('selectedFile ', selectedFile);
      reader.onloadend = () => {
        // Set the URL for the profile picture to the result from FileReader
        setProfilePictureUrl(reader.result);
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (profilePicture) {
        handleProfilePicture();
      }
    }
  }, []);

  //--- about text update handling
  const [editProfile, setEditProfile] = useState(false);
  const handleAboutTxt = () => {
    setEditProfile(!editProfile);
  };

  const [aboutTxt, setAboutTxt] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  //--- video update handling
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [profileVideo, setProfileVideo] = useState(null);
  const [profileVideoPreview, setProfileVideoPreview] = useState(null);
  const [profileVideoLoad, setProfileVideoLoad] = useState(false);

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'video/mp4') {
      setSelectedVideo(file);
      setvideoLoading(true); // Start loading

      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileVideoPreview(event.target.result);
        setvideoLoading(false); // Stop loading
      };
      reader.readAsDataURL(file);
      document.getElementById('videoTitle').innerText = file.name;
    } else {
      toast.error(t('Please upload a valid MP4 video file.'));
      event.target.value = null; // Clear the input
    }
  };

  const handleSave = () => {
    setVisible2(!visible2);
    setProfileVideo(profileVideoPreview);
  };
  //--- end video update handling

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const imgeUrl = process.env.REACT_APP_IMAGE_URL;

  //---- Fetching Roles
  const roleOptions = userRoles.map((role) => ({
    value: role._id,
    label: role.name,
  }));

  const handleRoleChange = (selectedOptions) => {
    // setRolesEdit(true);
    // setSelectedRoles(selectedOptions || []);
    if (selectedOptions.length <= 3) {
      setRolesEdit(true);
      setSelectedRoles(selectedOptions);
    } else {
      // alert("You can only select up to 3 roles.");
      setAlertVisible(true);
    }
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const language = localStorage.getItem("language");
        // console.log("roles language : ", language);
        const response = await fetch(`${apiUrl}/roles`, {
          method: "GET",
          headers: {
            "accept-language": language,
          },
        });

        if (!response.ok) {
          console.log("Network response was not ok");
        }

        const data = await response.json();
        setUserRoles(data.data);

        if (data.result === "success") {
          // console.log(data.message);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles();
  }, []);

  const handleProfileUpdate = async () => {
    if (selectedRoles.length === 0 && rolesEdit) {
      setBlocking(false);
      toast.error('At least one roles must be selected')
    } else {
      setEditInput(false);
      setLoading2(true);
      setBlocking(true);
      try {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("about", aboutTxt);
        if (profilePicture) {
          formData.append("profile_image", profilePicture);
        }
        if (selectedVideo) {
          formData.append("profile_video", selectedVideo);
        }
        if (Array.isArray(selectedRoles)) {
          if (selectedRoles.length === 0 && rolesEdit) {
            // toast.error('At least one roles must be selected');

          } else {
            selectedRoles.forEach((role) => {
              formData.append("roles", role.value);
            });
            console.log('value roles');
          }
        } else {
          console.error("selectedRoles is not an array:", selectedRoles);
        }

        const response = await fetch(`${apiUrl}/update-profile`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "accept-language": language,
          },
          body: formData,
        });

        const responseData = await response.json();

        if (responseData.result === "success") {
          setEditProfile(false);
          setBlocking(false);
          fetchUserData();
          toast.success(responseData.message);
          setLoading2(false);
        } else {
          toast.error(responseData.message || "Failed to update profile");
          setLoading2(false);
          setBlocking(false);
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        setLoading2(false);
        setBlocking(false);
      }
    }
  };

  const handleProfilePicture = async () => {
    setLoading2(true);
    setProfileCropModal(false);

    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("about", aboutTxt);
      if (profilePicture) {
        formData.append("profile_image", profilePicture);
      }
      if (selectedVideo) {
        formData.append("profile_video", selectedVideo);
      }
      if (Array.isArray(selectedRoles)) {
        if (selectedRoles.length === 0 && rolesEdit) {
          const emptyRoles = [];
          // emptyRoles.length = 0
          // formData.append("roles", emptyRoles);
          // console.log('empty roles');
        } else {
          selectedRoles.forEach((role) => {
            formData.append("roles", role.value);
          });
          console.log('value roles');
        }
      } else {
        console.error("selectedRoles is not an array:", selectedRoles);
      }

      const response = await fetch(`${apiUrl}/update-profile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "accept-language": language,
        },
        body: formData,
      });

      const responseData = await response.json();
      // console.log(responseData);

      if (responseData.result === "success") {
        fetchUserData();
        setLoading2(false);
        setLoading(false);
        setCroppedImages([])
      } else {
        // toast.error(responseData.message || "Failed to update profile");
        console.log('error')
        setLoading(false);
        setLoading2(false);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setLoading2(false);
      setLoading(false);
    }
  };

  //-------- end edit profile API integration --------//

  //-------- create event API integration --------//
  const [eventName, setEventName] = useState("");
  const [filmName, setFilmName] = useState("");
  const [eventLink, setEventLink] = useState("");
  const [eventDayTime, setEventDayTime] = useState("");

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setEventDayTime(selectedDate + " " + eventDayTime.split(" ")[1]);
  };

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    setEventDayTime(eventDayTime.split(" ")[0] + " " + selectedTime);
  };

  const handleAddEvent = async () => {
    setLoading(true);
    // console.log("event date and time : ", eventDayTime);
    if (
      eventName === "" ||
      filmName === "" ||
      eventLink === "" ||
      eventDayTime === ""
    ) {
      toast.error("Please Fill in All required fields!");
    } else {
      setVisible(!visible);
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/event`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "accept-language": language,
          },
          body: JSON.stringify({
            name: eventName,
            film_name: filmName,
            link: eventLink,
            datetime: eventDayTime,
          }),
        });

        const data = await response.json();

        if (!response.ok) {
          console.log("Faild to create event!", data.message);
          toast.error(data.message);
          setBlocking(false);
        }

        if (data.result === "success") {
          // console.log("Event added Successfully");
          toast.success(data.message);
          fetchEvents();
          setLoading(false);
          setBlocking(false);
        }
      } catch (error) {
        console.error("Error creating event:", error);
        setBlocking(false);
      }
    }
    setLoading(false);
  };
  // ------ end create event -------//

  //---- fetch Upcoming Events ----//
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const fetchEvents = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/event/upcoming`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "accept-language": language,
        },
        body: JSON.stringify({
          user_id: userid,
        }),
      });
      const data = await response.json();
      setUpcomingEvents(data.data);
      setLoading(false);
      if (!response.ok) {
        console.log("Network response was not ok ", data.message);
      }
      if (data.result === "success") {
        // console.log(data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEvents();
  }, [apiUrl]);

  //------- get user data --------//
  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState("");
  const [roleset, setroledate] = useState([]);

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userid");
    setLoading3(true);
    setProfileVideoLoad(true);
    try {
      const response = await axios.post(
        `${apiUrl}/get-user`,
        {
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "accept-language": language,
          },
        }
      );

      if (response.data.result === "success") {
        localStorage.setItem('profileComplete', response.data.data.profile_complete);
        if (response.data.data.profile_complete === 'incomplete') {
          navigate('/settings');
          return;
        } else {
          setLoading3(false);
          setUserData(response.data.data);
          setLoading(false);
          setProfileVideoLoad(false);

          if (response.data.data.userDetail) {
            setProfilePicture(
              `${imgeUrl}/${response.data.data.userDetail.profile_image}`
            );

            localStorage.setItem(
              "userImg",
              response.data.data.userDetail.profile_image
            );

            if (response.data.data.userDetail.profile_video) {
              setProfileVideo(
                `${imgeUrl}/${response.data.data.userDetail.profile_video}`
              );
            }

            setProfilePictureUrl(
              `${response.data.data.userDetail.profile_image}`
            );

            setAboutTxt(response?.data?.data?.userDetail?.about);

            const userRoleIds = response.data.data.userDetail.roles.map(
              (role) => role._id
            );

            // console.log('user roles :', response.data.data.userDetail.roles);

            // const selectedRoleOptions = userRoles
            //   .filter((role) => userRoleIds.includes(role._id))
            //   .map((role) => ({
            //     value: role._id,
            //     label: role.name,
            //   }));
            // Set selected roles directly from the roles in the API response
            const selectedRoleOptions = response.data.data.userDetail.roles.map(
              (role) => ({
                value: role._id,
                label: role.name,
              })
            );
            setSelectedRoles(selectedRoleOptions);
            setroledate(response.data.data.userDetail.roles);
            console.log("Selected Roles:", selectedRoleOptions);
          }

          if (response.data.data.first_name) {
            setUserName(response.data.data.first_name + " " + response.data.data.sir_name);
          } else {
            setUserName('Null');
          }
        }
      } else {
        setProfileVideoLoad(false);
        setLoading3(false);
        setLoading(false);
        throw new Error(response.data.message || "Failed to fetch user data");

      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setProfileVideoLoad(false);
      setLoading(false);
      setLoading3(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);


  const handleChange = (e) => {
    const text = e.target.value;
    setAwardDesc(text);

  };

  const handleClearAward = () => {
    setAwardDesc('');
    setAwardTitle('');
    setVisible3(false);
    setAwardEditMode(false);
  }

  //----- add awards API integration -----//

  const handleAwardSubmit = () => {
    if (awardEditMode) {
      handleEditAward(awardId);
    } else {
      handleAwardSave();
    }
  }

  const handleAwardSave = async () => {
    setBlocking(true);
    setVisible3(false);
    try {
      const language = localStorage.getItem("language");
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${apiUrl}/award`,
        {
          title: awardTitle,
          description: awardDesc,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "accept-language": language,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.result === "success") {
        // console.log(response.data.message);
        toast.success(response.data.message);
        fetchAwards(); // Fetch awards after successfully adding a new award
      } else if (response.data.result === "error") {
        toast.error(response.data.message);
        // console.log(response.data);
      }
      setAwardDesc('');
      setAwardTitle('');
      setBlocking(false);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        console.error("Error adding award:", errorMessage);
        toast.error(errorMessage);
      } else {
        console.error("Error adding award:", error.message);
        toast.error(error.message);
      }
      setBlocking(false);
    }
  };
  //----- add awards API integration end's here -----//

  //----- fetching awards API integration -----//
  const fetchAwards = async () => {
    setAwardLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${apiUrl}/award`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "accept-language": language,
        },
      });
      if (response.data.result === "success") {
        setAwards(response.data.data);
        setAwardLoading(false);
      }
    } catch (error) {
      console.error('Error fetching awards:', error);
      if (error.response && error.response.data) {
        if (error.response.data.message === 'Invalid token') {
          navigate('/login');
        }
      } else {
        // Handle other types of errors if necessary
        console.error('Unexpected error:', error.message);
      }
    }
  };

  useEffect(() => {
    fetchAwards();
  }, []);

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'yyyy-MM-dd, hh:mm a');
  };

  //--- delete award api integration
  const [deleteAwardId, setDeleteAwardId] = useState(null);
  const [deleteAward, setDeleteAward] = useState(false);
  const deleteAlertPopup = (awardId) => {
    setDeleteAlert(true);
    setDeleteAwardId(awardId);
  }

  const handleDeleteAward = async () => {
    setBlocking(true);
    setDeleteAward(true);
    setDeleteAlert(false);
    try {
      const language = localStorage.getItem("language");
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/award/${deleteAwardId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'accept-language': language
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        setBlocking(false);
        toast.error(errorData.message);
        throw new Error(`HTTP error! status: ${response.status}, Message: ${errorData.message}`);
      }

      console.log(`Award deleted successfully`);
      fetchAwards();
      setBlocking(false);
      setDeleteAlert(false);
      setDeleteAward(false);
      toast.success(`Award deleted successfully`);
    } catch (error) {
      setBlocking(false);
      console.error(`Failed to delete the award:`, error);
    }
  }

  const handlePopUpOpen = (id) => {
    setBlocking(true);
    handleGetAward(id);
    if (!blocking) {
      setMenuListOpen(false);
      // setVisible3(true);
      setAwardEditMode(true);
    }
  }
  //----- get single award 
  const handleGetAward = async (id) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${apiUrl}/award/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "accept-language": language,
        },
      });
      if (response.data.result === "success") {
        setAwardId(response.data.data._id);
        setAwardTitle(response.data.data.title);
        setAwardDesc(response.data.data.description);
        setBlocking(false);
        setVisible3(true);
      }
    } catch (error) {
      console.error('Error fetching award:', error);
    }
  }

  //---- edit award API integration
  const handleEditAward = async (awardId) => {
    setBlocking(true);
    setVisible3(false);
    console.log('edit mode');
    try {
      const language = localStorage.getItem("language");
      const token = localStorage.getItem("token");
      const response = await axios.patch(
        `${apiUrl}/award`,
        {
          award_id: awardId,
          title: awardTitle,
          description: awardDesc,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "accept-language": language,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.result === "success") {
        toast.success(response.data.message);
        fetchAwards();
      } else if (response.data.result === "error") {
        toast.error(response.data.message);
      }
      setAwardDesc('');
      setAwardTitle('');
      setBlocking(false);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        console.error("Error editing award:", errorMessage);
        toast.error(errorMessage);
      } else {
        console.error("Error editing award:", error.message);
        toast.error(error.message);
      }
      setBlocking(false);
    }
    setAwardEditMode(false);
  }

  // delete profile picture
  const deleteProfilePicture = async () => {
    const token = localStorage.getItem("token");
    const userid = localStorage.getItem("userid");
    try {
      const response = await axios.get(`${apiUrl}/remove-profile-image`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "accept-language": language,
        },
        body: {
          user_id: userid
        }
      });
      if (response.data.result === "success") {
        toast.success(response.data.message);
        fetchUserData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error('Error fetching awards:', error);
      if (error.response && error.response.data) {
        if (error.response.data.message === 'Invalid token') {
          navigate('/login');
        }
      } else {
        // Handle other types of errors if necessary
        console.error('Unexpected error:', error.message);
        toast.error(error.response.data.message);
      }
    }
  };

  //--- about text restriction function
  const urlRegex = /((https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})([^\s]*))/g;

  const [alertMessage, setAlertMessage] = useState('');

  const handleAboutChange = (e) => {
    // Get the value of the textarea
    const inputValue = e.target.value;
    if (urlRegex.test(inputValue)) {
      setAlertMessage(`${t("You cannot add any external link in your bio.")}`);
    } else {
      setAlertMessage('');
    }

    // Remove URLs by replacing them with an empty string
    const sanitizedValue = inputValue.replace(urlRegex, '');

    setAboutTxt(sanitizedValue);
  };


  

  return (
    <>
      <BlockUi tag="div" blocking={blocking}>
        {/*/--- navbar ---/*/}
        <Navbar authorized={true} />
        

        {/*/--- page wrapper section ---/*/}
        <div className="page-wrapper position-relative">
          {/* bio section */}
          <section className="user-profile-wrapper">
            <CContainer>
              <CForm>
                <CRow>
                  <CCol lg={4}>
                    <div className="profile-banner">
                      <div className="profile-banner-wrapper">
                        <div className="profile-banner-wrapper-content">
                          <div className="user-profile-picture text-center mx-auto">
                            {loading2 ? (
                              <Spinner />
                            ) : (
                              <>
                                {profilePictureUrl && profilePictureUrl !== 'null' ?
                                  <img src={profilePicture} alt="Profile" />
                                  :
                                  <img src={userAvatar} alt="avatar" />
                                }
                              </>
                            )}

                            {/*/--- delete profile picture start ---/*/}
                            {profilePictureUrl && profilePictureUrl !== 'null' ?
                              <Link className="delete-profile-btn" onClick={() => deleteProfilePicture()}>-</Link>
                              :
                              <></>
                            }

                            {/*/--- delete profile picture end ---/*/}


                            {/*/-- edit profile picture btn --/*/}
                            {editProfile ?
                              <>
                                <Link to="#" onClick={() => setProfileCropModal(true)} className={`edit-profile-picture d-flex`}>
                                  <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_2215_6603)">
                                      <path
                                        d="M29.589 9.366L19.62 19.336C18.746 20.209 17.211 21.353 16.124 21.94L14.083 23.042C13.632 23.286 13.231 23.404 12.86 23.404C12.353 23.404 11.915 23.174 11.659 22.772C11.336 22.265 11.359 21.608 11.727 20.82L12.763 18.604C13.292 17.47 14.401 15.887 15.287 15.001L25.256 5.031L29.589 9.366ZM31.041 7.915L31.097 7.859C32.298 6.659 32.302 4.709 31.107 3.514C29.953 2.359 27.922 2.365 26.763 3.525L26.707 3.58L31.041 7.915ZM25.07 28.327C25.072 27.76 24.615 27.299 24.048 27.296L1.03101 27.189H1.02701C0.462007 27.189 0.00300654 27.646 6.53956e-06 28.211C-0.00199346 28.778 0.455007 29.24 1.02201 29.242L24.039 29.348H24.044C24.609 29.349 25.068 28.892 25.07 28.327Z"
                                        fill="white"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_2215_6603">
                                        <rect width="32" height="32" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </Link>
                                {/* <label
                                  htmlFor="fileInput"
                                  className={`edit-profile-picture d-flex`}
                                >
                                  <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_2215_6603)">
                                      <path
                                        d="M29.589 9.366L19.62 19.336C18.746 20.209 17.211 21.353 16.124 21.94L14.083 23.042C13.632 23.286 13.231 23.404 12.86 23.404C12.353 23.404 11.915 23.174 11.659 22.772C11.336 22.265 11.359 21.608 11.727 20.82L12.763 18.604C13.292 17.47 14.401 15.887 15.287 15.001L25.256 5.031L29.589 9.366ZM31.041 7.915L31.097 7.859C32.298 6.659 32.302 4.709 31.107 3.514C29.953 2.359 27.922 2.365 26.763 3.525L26.707 3.58L31.041 7.915ZM25.07 28.327C25.072 27.76 24.615 27.299 24.048 27.296L1.03101 27.189H1.02701C0.462007 27.189 0.00300654 27.646 6.53956e-06 28.211C-0.00199346 28.778 0.455007 29.24 1.02201 29.242L24.039 29.348H24.044C24.609 29.349 25.068 28.892 25.07 28.327Z"
                                        fill="white"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_2215_6603">
                                        <rect width="32" height="32" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </label>
                                <input
                                  id="fileInput"
                                  type="file"
                                  accept="image/*"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                /> */}
                              </>
                              :
                              <></>
                            }
                          </div>
                          <div className="user-bio">
                            <h2 className="user-name">{userName}</h2>
                            <div className="user-role-wrapper">
                              <ul className="user-profile-roles-wrapper justify-content-start">
                                {loading3 ?
                                  <Spinner />
                                  :
                                  <>
                                    {roleset.length > 0 ?
                                      <>
                                        {roleset.map((role, index) => (
                                          <li
                                            key={index}
                                            className="user-profile-roles-show"
                                          >
                                            {role.name}
                                            {index < roleset.length - 1 &&
                                              <svg height="15" width="10">
                                                <line x1="5" y1="0" x2="5" y2="100" style={{ stroke: '#ccc', strokeWidth: '2' }} />
                                              </svg>} {" "}
                                          </li>

                                        ))}
                                      </>
                                      :
                                      <p>{t('Add your roles here!')}</p>
                                    }
                                  </>
                                }
                              </ul>

                              {/*/-- edit role btn --/*/}
                              {editProfile ?
                                <Link
                                  className={`edit-role d-flex`}
                                  onClick={handleEditInput}
                                >
                                  <svg
                                    width="21"
                                    height="20"
                                    viewBox="0 0 21 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_2229_6614)">
                                      <path
                                        d="M18.9931 5.85387L12.7625 12.0851C12.2163 12.6307 11.2569 13.3457 10.5775 13.7126L9.30188 14.4014C9.02 14.5539 8.76938 14.6276 8.5375 14.6276C8.22063 14.6276 7.94688 14.4839 7.78688 14.2326C7.585 13.9157 7.59938 13.5051 7.82938 13.0126L8.47688 11.6276C8.8075 10.9189 9.50063 9.9295 10.0544 9.37575L16.285 3.1445L18.9931 5.85387ZM19.9006 4.947L19.9356 4.912C20.6863 4.162 20.6888 2.94325 19.9419 2.19637C19.2206 1.4745 17.9513 1.47825 17.2269 2.20325L17.1919 2.23762L19.9006 4.947ZM16.1688 17.7045C16.17 17.3501 15.8844 17.062 15.53 17.0601L1.14438 16.9932H1.14188C0.788754 16.9932 0.501879 17.2789 0.500004 17.632C0.498754 17.9864 0.784379 18.2751 1.13875 18.2764L15.5244 18.3426H15.5275C15.8806 18.3432 16.1675 18.0576 16.1688 17.7045Z"
                                        fill="white"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_2229_6614">
                                        <rect
                                          width="20"
                                          height="20"
                                          fill="white"
                                          transform="translate(0.5)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </Link>
                                :
                                <></>
                              }
                            </div>
                          </div>

                          {/*/-- role edit mode start here --/*/}

                          {editInput ? (
                            <div className="pt-3 w-100">
                              <Select
                                isMulti
                                name="roles"
                                className="user-profile-roles-input"
                                options={userRoles.map((role) => ({
                                  value: role._id,
                                  label: role.name,
                                }))}
                                value={selectedRoles}
                                onChange={handleRoleChange}
                                placeholder={t("Select roles")}
                              />
                            </div>
                          ) : (
                            <p></p>
                          )}
                          {/* role edit mode end here */}

                          <div className="user-awards mt-5">
                            {awards.length > 0 && (
                              <div className="award-info mx-auto">
                                <div className="awards-icon">
                                  <img src={awardIcon} alt="award" />

                                  <div className="awards-desc-wrapper">
                                    {awardLoading ?
                                      < Spinner />
                                      :
                                      <>
                                        {awards.map((award) => (
                                          <div className="position-relative award-details mb-3" key={award._id}>
                                            <Link className="award-menu" onClick={() => handleMenuList(award._id)}>
                                              <svg width="32" height="8" viewBox="0 0 42 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="4" cy="4" r="4" fill="#a09c9c" />
                                                <circle cx="21" cy="4" r="4" fill="#a09c9c" />
                                                <circle cx="38" cy="4" r="4" fill="#a09c9c" />
                                              </svg>
                                            </Link>
                                            {menuListOpen === award._id && (
                                              <div className='edit-calls-menu-list award-menu-list'>
                                                <Link className="edit-award-btn" onClick={() => handlePopUpOpen(award._id)}>{t('Edit')}</Link>
                                                <Link className='del-award-btn' onClick={() => deleteAlertPopup(award._id)}>
                                                  {("Delete")}
                                                </Link>
                                              </div>
                                            )}
                                            <div className="award-title">{award.title}</div>
                                            <div className="awards-desc">{award.description}</div>
                                          </div>
                                        ))}
                                      </>
                                    }
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="text-center award-add-btn">
                              <p className="d-inline mb-0">{t("Awards")}</p>
                              {awards.length < 3 && (
                                <Link onClick={() => setVisible3(!visible3)}>
                                  <img src={addBtn} alt="add" />
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CCol>
                  <CCol lg={8}>
                    <div className="user-about-wrapper">
                      {/* edit profile btn */}
                      <Link className="edit-about-btn" onClick={handleAboutTxt}>
                        <img src={editImg} alt="edit" />
                        <span>{t("Edit Profile")}</span>
                      </Link>

                      <div className="user-bio-video-clip mt-lg-0 mt-5">
                        {profileVideoLoad ? (
                          <div className="w-100 mb-3" style={{ height: '340px', border: '1px solid #fff' }} >
                            <Spinner />
                          </div>
                        ) : (
                          <>
                            {profileVideo !== 'null' && profileVideo !== undefined && profileVideo !== null ? (
                              <>
                                {/* {console.log('profileVideo front : ', profileVideo)} */}
                                <VideoPlayer videoUrl={`${profileVideo}`} width="100%" />
                              </>
                            ) : (
                              <div
                                className="no-video-wrapper video-frame mb-3"
                              // style={{ cursor: 'pointer' }} 
                              // onClick={() => setVisible2(!visible2)}
                              >
                                <img src={videoPlaceHolder} alt="no video" />
                              </div>
                            )}
                          </>
                        )}

                        {/* {profileVideo !== 'null' && profileVideo !== undefined && profileVideo !== null && profileVideoLoad === false && editProfile === true ? */}
                        {editProfile === true ?
                          <Link
                            className="edit-bio-video-clip"
                            onClick={() => setVisible2(!visible2)}
                          >
                            <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="18.1047" cy="17.9582" r="17.9582" fill="black" />
                              <g clipPath="url(#clip0_3713_5108)">
                                <path d="M27.4698 14.1173L20.9598 20.6279C20.3891 21.198 19.3867 21.9451 18.6768 22.3284L17.344 23.048C17.0495 23.2074 16.7876 23.2844 16.5454 23.2844C16.2143 23.2844 15.9283 23.1342 15.7611 22.8717C15.5502 22.5406 15.5652 22.1116 15.8055 21.597L16.482 20.1499C16.8275 19.4094 17.5517 18.3756 18.1303 17.797L24.6403 11.2864L27.4698 14.1173ZM28.418 13.1697L28.4546 13.1331C29.2389 12.3495 29.2415 11.0761 28.4611 10.2957C27.7075 9.5415 26.3812 9.54542 25.6244 10.3029L25.5878 10.3388L28.418 13.1697ZM24.5188 26.4993C24.5201 26.129 24.2217 25.828 23.8514 25.826L8.82073 25.7561H8.81812C8.44916 25.7561 8.14942 26.0546 8.14747 26.4235C8.14616 26.7938 8.44459 27.0955 8.81486 27.0968L23.8455 27.166H23.8488C24.2178 27.1667 24.5175 26.8682 24.5188 26.4993Z" fill="white" />
                              </g>
                              <defs>
                                <clipPath id="clip0_3713_5108">
                                  <rect width="20.8968" height="20.8968" fill="white" transform="translate(8.14648 8)" />
                                </clipPath>
                              </defs>
                            </svg>
                          </Link>
                          : <></>
                        }
                      </div>
                      <div className="user-about position-relative">
                        <div className="d-flex justify-content-between mb-2">
                          <h3>{t("About")}</h3>
                          <CButton className='share-btn' onClick={() => setShareVisible(true)} style={{ position: 'inherit' }}>
                            <img src={shareIcon} alt='icon' className='me-1' />
                            <span>{t("Share")}</span>
                          </CButton>
                        </div>
                        {editProfile ? (
                          <>
                            <textarea
                              placeholder={t('Write About user')}
                              value={aboutTxt}
                              onChange={handleAboutChange}
                            ></textarea>
                            {alertMessage && <p style={{ color: 'red' }}>{alertMessage}</p>}
                          </>
                        ) : (
                          <>
                            {aboutTxt != null && aboutTxt != 'null' && aboutTxt != 'Null' && aboutTxt != '' ? (
                              <p>{aboutTxt}</p>
                            ) : (
                              <p>No Data Available</p>
                            )}
                          </>

                        )}

                        {/* {loading2?
                          <Spinner/>
                        : */}
                        {editProfile ?
                          <div className="edit-about-wrapper text-lg-end mt-4">
                            <CButton
                              className="primary_btn"
                              onClick={() => { handleProfileUpdate(); }}
                            >
                              {t("Save")}
                            </CButton>
                          </div>
                          :
                          <></>
                        }
                        {/* } */}
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CForm>
            </CContainer>
          </section>

          {/*/--- event section ---/*/}
          <section className="event-wrapper">
            <CContainer>
              <CRow>
                <CCol md={6} xs={6}>
                  <p className={"event_title"}>{t("My Upcoming Event")}</p>
                </CCol>
                <CCol lg={12}>
                  <div className="table-wrapper mt-5">
                    {upcomingEvents.length > 0 ? (
                      <CTable responsive>
                        <CTableBody>
                          {upcomingEvents.map((event) => (
                            <CTableRow key={event._id}>
                              <CTableDataCell>
                                <span className="d-block" style={{ width: '180px' }}>{t("with")} {event.user_id.first_name}</span>
                              </CTableDataCell>
                              <CTableDataCell>
                                <span className="d-block" style={{ width: '180px' }}>{formatDateTime(event.datetime)}</span>
                              </CTableDataCell>
                              <CTableDataCell>
                                <span className="d-block" style={{ width: '180px' }}>{t("for")} "{event.film_name}"</span>
                              </CTableDataCell>
                              <CTableDataCell>
                                <Link to={event.link}>{event.link}</Link>
                              </CTableDataCell>
                            </CTableRow>
                          ))}
                        </CTableBody>
                      </CTable>
                    ) : (
                      <p>{t("No upcoming events found.")}</p>
                    )}
                  </div>
                  <div className="text-end add-event-wrapper">
                    <CButton className="primary_btn mt-4" onClick={() => setVisible(!visible)}>
                      {t("Add Event")}
                    </CButton>
                  </div>
                </CCol>
              </CRow>
            </CContainer>
          </section>

          {/*/--- media section ---/*/}
          {/* <EditUserMedia editProfile={true} setBlocking={setBlocking} /> */}
          <EditUserMedia2 editProfile={true} setBlocking={setBlocking} />
        </div>

        {/*/---- Footer ----/*/}
        <Footer />

        {/*/---- copy right ----/*/}
        <CopyRight />

        {/*/---- modals to add event ----/*/}
        <CModal
          className="photos-upload-modal"
          alignment="center"
          visible={visible}
          onClose={() => setVisible(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalBody>
            <div className="upload-photo-modal-body-content film-upload-content text-center">
              <CForm>
                <h3>{t("Add Event Details")}</h3>
                <div className="w-100 d-flex">
                  <div className="w-50 p-2">
                    <input
                      type="text"
                      placeholder={`${t("Event Name")} *`}
                      value={eventName}
                      onChange={(e) => setEventName(e.target.value)}
                    />
                  </div>

                  <div className="w-50 p-2">
                    <input
                      type="text"
                      placeholder={`${t("Film Name")}*`}
                      value={filmName}
                      onChange={(e) => setFilmName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-100 d-flex">
                  <div className="w-100 p-2">
                    <input
                      type="text"
                      placeholder={`${t("Enter Link")} *`}
                      value={eventLink}
                      onChange={(e) => setEventLink(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-100 d-flex">
                  <div className="w-50 p-2">
                    <span className="input-label">{t('Event Date*')}</span>
                    <input
                      type="date"
                      placeholder=""
                      value={eventDayTime.split(" ")[0]}
                      onChange={handleDateChange}
                    />
                  </div>

                  <div className="w-50 p-2">
                    <span className="input-label">{t('Event Time*')}</span>
                    <input
                      type="time"
                      placeholder=""
                      value={eventDayTime.split(" ")[1]}
                      onChange={handleTimeChange}
                    />
                  </div>
                </div>
                <div className="w-100 d-flex">
                  <div className="w-50 mt-4 p-2">
                    <CButton
                      className="secondary_outline_btn w-100"
                      onClick={() => setVisible(!visible)}
                    >
                      {t("Cancel")}
                    </CButton>
                  </div>
                  <div className="w-50 mt-4 p-2">
                    <CButton
                      className="primary_btn w-100"
                      onClick={(e) => { handleAddEvent(); setBlocking(true); }}
                      style={{ marginTop: '15px' }}
                    >
                      {t("Save")}
                    </CButton>
                  </div>
                </div>
              </CForm>
            </div>
            <CButton
              className="close_modal_btn"
              onClick={() => setVisible(false)}
            ></CButton>
          </CModalBody>
        </CModal>

        {/*/---- modals to update video ----/*/}
        <CModal
          className="photos-upload-modal"
          alignment="center"
          visible={visible2}
          onClose={() => setVisible2(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalBody>
            <div className="upload-photo-modal-body-content film-upload-content text-center">
              <h3>{t("upload your video in MP4")}</h3>
              <span className='file-restriction-txt'>{t('To upload file size is (Max 10mb)')}</span>
              <div className="text-center">
                <label htmlFor="photoInput" className="secondary_outline_btn">
                  {t("Browse")}
                </label>
                <input
                  type="file"
                  id="photoInput"
                  accept="video/mp4"
                  onChange={handleVideoChange}
                  hidden
                />
                <p id="videoTitle" className="text-white text-center"></p>
              </div>
              <div className="mt-4">
                <CButton className="primary_btn" onClick={handleSave}>
                  {t("Save")}
                </CButton>
              </div>
            </div>
            <CButton
              className="close_modal_btn"
              onClick={() => setVisible2(false)}
            ></CButton>
          </CModalBody>
        </CModal>

        {/*/---- modals to add awards ----/*/}
        <CModal
          className="photos-upload-modal"
          alignment="center"
          visible={visible3}
          onClose={() => handleClearAward()}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalBody>
            <div className="upload-photo-modal-body-content film-upload-content text-center">
              <CForm>
                <h3>{t("Add Awards")}</h3>
                <div className="w-100">
                  <input
                    type="text"
                    placeholder={`${t("Award Title")} *`}
                    value={awardTitle}
                    onChange={(e) => setAwardTitle(e.target.value)}
                  />
                </div>
                <div className="w-100 mt-4">
                  <textarea
                    rows={6}
                    placeholder={`${t("Add description of maximum 50 words.")} *`}
                    value={awardDesc}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-100 mt-4 d-flex">
                  <div className="w-50 p-2">
                    <CButton
                      className="secondary_outline_btn w-100"
                      onClick={() => handleClearAward()}
                    >
                      {t("Cancel")}
                    </CButton>
                  </div>
                  <div className="w-50 p-2">
                    <CButton
                      className="primary_btn w-100"
                      onClick={handleAwardSubmit}
                      style={{ marginTop: '15px' }}
                    >
                      {t("Save")}
                    </CButton>
                  </div>
                </div>
              </CForm>
            </div>
            <CButton
              className="close_modal_btn"
              onClick={() => handleClearAward()}
            ></CButton>
          </CModalBody>
        </CModal>

        {/*/--- alert modal ---/*/}
        <CModal
          alignment="center"
          visible={alertvisible}
          onClose={() => setAlertVisible(false)}
          aria-labelledby="VerticallyCenteredExample"
          className='confirmation-modal'
        >
          <CModalBody className='pt-5'>
            <p className='confirmation-txt'>{t("You can only select up to 3 roles.")}</p>
          </CModalBody>
          <CModalFooter>
            <CButton className='confirmation-btn primary_btn' onClick={() => setAlertVisible(false)}>{t('OK')}</CButton>
          </CModalFooter>
        </CModal>

        {/*/--- delete alert modal ---/*/}
        <CModal
          alignment="center"
          visible={deleteAlert}
          onClose={() => setDeleteAlert(false)}
          aria-labelledby="VerticallyCenteredExample"
          className='confirmation-modal'
        >
          <CModalBody className='pt-5'>
            <p className='confirmation-txt'>{t("Do you realy want to delte this award")}</p>
          </CModalBody>
          <CModalFooter>
            <CButton className='confirmation-btn secondary_outline_btn ' onClick={() => setDeleteAlert(false)} disabled={deleteAward}>
              {t("No")}
            </CButton>
            <CButton className='confirmation-btn primary_btn' onClick={() => handleDeleteAward()} disabled={deleteAward}>{t('Yes')}</CButton>
          </CModalFooter>
        </CModal>

        {/*/--- Share Modal ---/*/}
        <CModal
          className='photos-upload-modal'
          alignment="center"
          visible={shareVisible}
          onClose={() => setShareVisible(false)}
          aria-labelledby="VerticallyCenteredExample"
        >
          <CModalBody>
            <div className='upload-photo-modal-body-content film-upload-content py-3 px-4'>
              <h3 className='text-start'>{t('Share this profile')}!</h3>
              <div className='social-platforms d-flex align-items-center justify-content-center mt-4'>
                {/* LinkedIn */}
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(`${shareUrl}/user-profile/${userid}/${userName}`)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedInI} alt='LinkedIn' />
                </a>

                {/* Facebook */}
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`${shareUrl}/user-profile/${userid}/${userName}/`)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-3"
                >
                  <img src={fbI} alt='Facebook' />
                </a>

                {/* Twitter */}
                <a
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(`${shareUrl}/user-profile/${userid}/${userName}`)}&text=${encodeURIComponent('Check out this profile!')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitterI} alt='Twitter' />
                </a>

                {/* Discord */}
                {/* <a
                  href={`https://discord.com/channels/@me`} // Discord doesn't have direct sharing, so adjust as needed
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={discordI} alt='Discord' />
                </a> */}

                {/* WhatsApp */}
                {/* <a
                  href={`https://wa.me/?text=${encodeURIComponent(`Check out this profile: ${shareUrl}/user-profile/${userid}/${userName}`)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={whatsappI} alt='WhatsApp' />
                </a> */}
              </div>

              <div className='copy-url-wrapper mt-5 position-relative'>
                <input
                  type='text'
                  defaultValue={`${shareUrl}/user-profile/${userid}/${userName}`}
                  placeholder='User Profile URL'
                  readOnly
                />
                <Link
                  to='#'
                  onClick={() => {
                    const link = `${shareUrl}/user-profile/${userid}/${userName}`;
                    navigator.clipboard.writeText(link)
                      .then(() => {
                        setAlertTxt('Link Copied!');
                        setTimeout(() => setAlertTxt(''), 3000);
                      })
                      .catch((err) => {
                        console.error('Failed to copy the link: ', err);
                        setAlertTxt('Failed to copy!');
                        setTimeout(() => setAlertTxt(''), 3000);
                      });
                  }}
                >
                  <img src={copyI} alt='icon' />
                </Link>
                <span>{alertTxt}</span>
              </div>
            </div>
            <CButton className='close_modal_btn' onClick={() => setShareVisible(false)}></CButton>
          </CModalBody>
        </CModal>



        {/* modal to crop profile pictire */}
        <CModal
          className="photos-upload-modal"
          alignment="center"
          visible={profileCropModal}
          onClose={() => setProfileCropModal(false)}
          size="lg"
        >
          <CModalBody>
            <div className="upload-photo-modal-body-content text-center">
              <h3>{t('upload your Photo')}</h3>
              <span className="file-restriction-txt">{t('To upload file size is (Max 5mb) and maximum dimensions are 300X300')}</span>
              {imgSrc && (
                <div style={{ position: 'relative', width: '100%', height: '250px' }}>
                  <Cropper
                    image={imgSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={1} // Square crop
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    style={{
                      containerStyle: { borderRadius: '50%' }, // Optional, for styling container
                      cropAreaStyle: { borderRadius: '50%' }, // Circular cropper appearance
                    }}
                  />
                </div>
              )}
              <label htmlFor="photoInput" className="secondary_outline_btn">
                {t('Browse')}
              </label>
              <input
                type="file"
                id="photoInput"
                accept="image/*"
                onChange={handleImageChange}
                hidden
              />
              {imgSrc && (
                <CButton className="primary_btn mt-3" onClick={saveCroppedImage}>
                  {t('Save Cropped Image')}
                </CButton>
              )}
              <div className='text-center'>
                {croppedImages.length > 0 && (
                  <div className="mt-2">
                    <h4>{t('Cropped Images')}</h4>
                    {croppedImages.map((img, idx) => (
                      <img
                        key={idx}
                        src={img.objectUrl}
                        alt={`Cropped ${idx}`}
                        style={{
                          margin: '10px',
                          width: '100px',
                          height: '100px',
                          borderRadius: '50%', // Make the displayed image circular
                        }}
                      />
                    ))}
                  </div>
                )}
                <CButton
                  className="primary_btn mt-3"
                  onClick={handleProfilePicture}
                >
                  {t('Upload')}
                </CButton>
              </div>
            </div>
            <CButton className='close_modal_btn' onClick={() => setProfileCropModal(false)}></CButton>
          </CModalBody>
        </CModal>

      </BlockUi>
    </>
  );
}

export default EditProfile;
