import React from 'react';
import b1 from '../../assets/images/blogs/b1.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function ReleventBlogCard({ blog }) {

    const { t } = useTranslation();

    return (
        <>
            <div className='relevent-blog-card-wrapper'>
                <div className='relevent-blog-img'>
                    <Link to={`/blog-detail/${blog._id}`}>
                        <img src={b1} alt='blog'/>
                    </Link>
                </div>
                <div className='relevent-blog-details mt-2'>
                    <h3>
                        <Link to={`/blog-detail/${blog._id}`}>
                            {blog.title}
                        </Link>
                    </h3>
                    <p>
                        {t("Published on")}: 
                        <span className='mx-2'>{new Date(blog.createdAt).toLocaleDateString()}</span>
                        {/* | <span className='ms-2'>23 min read</span> */}
                    </p>
                </div>
            </div>
        </>
    )
}

export default ReleventBlogCard;