import React from 'react';
import ReleventBlogCard from './ReleventBlogCard';
import EmptyReleventBlog from './EmptyReleventBlog';
import { useTranslation } from 'react-i18next';

function InstanceBlogs({ recentBlogs, relatedBlogs }) {

    const { t } = useTranslation();
    // Helper function to render blogs with empty placeholders
    const renderBlogs = (blogs) => {
        const blogCards = blogs.map((blog, index) => (
            <ReleventBlogCard key={index} blog={blog} />
        ));
        // Add empty cards if there are fewer than 2 blogs
        while (blogCards.length < 2) {
            blogCards.push(
                <EmptyReleventBlog key={`empty-${blogCards.length}`} />
            );
        }
        return blogCards;
    };

    return (
        <div className="relevent-blogs-wrapper pt-0">
            <div className="recent-blogs-wrapper pt-0">
                <h2>Recent Article</h2>
                <div className="">
                    {renderBlogs(recentBlogs)}
                </div>
            </div>

            <div className="related-blogs-wrapper">
                <h2 className="mt-5">{t("Related Article")}</h2>
                <div className="mt-4">
                    {renderBlogs(relatedBlogs)}
                </div>
            </div>
        </div>
    );
}

export default InstanceBlogs;
