import React, { useEffect, useState, useRef } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { Link, useParams } from "react-router-dom";
import { io } from 'socket.io-client';
import axios from "axios";
import { useTranslation } from 'react-i18next';

// Components
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CopyRight from '../components/CopyRight';
import avatar from '../assets/images/profile_avatar.png';
import Spinner from './loader/Spinner';
import './loader/Spinner.css';
import { COffcanvas, COffcanvasBody, COffcanvasHeader, CCloseButton } from '@coreui/react';
import { CButton } from '@coreui/react';

// Singleton socket instance (ensure a single connection)
const socketUrl = process.env.REACT_APP_Socket_URL;
const socket = io(socketUrl, {
  transports: ['websocket'],
});

function UserChatScreen() {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { userid } = useParams();
  const [receiverId, setReceiverId] = useState(userid);
  const senderId = localStorage.getItem("userid");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [chatThreads, setChatThreads] = useState([]);
  const [senderName, setSenderName] = useState('');
  const [senderImg, setSenderImg] = useState('');
  const [receiverImg, setReceiverImg] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const imgeUrl = process.env.REACT_APP_IMAGE_URL;
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const language = localStorage.getItem("language");
  const [activeThreadId, setActiveThreadId] = useState(null);



  const chatCardBodyRef = useRef(null);

  // Loaders
  const [isChatHistoryLoading, setIsChatHistoryLoading] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isChatThreadsLoading, setIsChatThreadsLoading] = useState(false);
  const [messageBeingSent, setMessageBeingSent] = useState(null);

  useEffect(() => {
    // Scroll to the bottom on component mount or when messages change
    if (chatCardBodyRef.current) {
      chatCardBodyRef.current.scrollTop = chatCardBodyRef.current.scrollHeight;
    }
  }, [messages]);



  // Fetch user data
  const fetchUserData = async (userId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${apiUrl}/get-user`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            'accept-language': language,
          },
        }
      );

      if (response.data.result === "success") {
        if (response.data.data.userDetail) {
          setReceiverImg(response.data.data.userDetail.profile_image || avatar);
        }
        setReceiverName(`${response.data.data.first_name} ${response.data.data.sir_name}`);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Fetch chat history
  const fetchChatHistory = (senderId, receiverId) => {
    setIsChatHistoryLoading(true);
    socket.emit("get-chat-history", { sender_id: senderId, receiver_id: receiverId });
    socket.emit("register", { user_id: senderId });
  };

  useEffect(() => {
    const userImg = localStorage.getItem("userImg");
    const userData = JSON.parse(localStorage.getItem('user') || '{}');
    if (userData) {
      setSenderName(`${userData.first_name} ${userData.sir_name}`);
    }
    setSenderImg(userImg || avatar);

    fetchUserData(userid);
    fetchChatHistory(senderId, userid);
  }, [userid]);

  // Socket connection and event listeners
  useEffect(() => {
    setIsChatThreadsLoading(true);
      socket.emit("get-chat-threads", { user_id: senderId });
      fetchChatHistory(senderId, receiverId);
    const connectSocket = () => {
      console.log("Socket connected:", socket.id);
      setIsChatThreadsLoading(true);
      socket.emit("get-chat-threads", { user_id: senderId });
      fetchChatHistory(senderId, receiverId);
    };

    socket.on("connect", connectSocket);

    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    socket.on(`${senderId}-send-message`, (message) => {
      console.log("New message received:", message);
      setIsSendingMessage(false);

      if (message.data.data.sender_id === receiverId) {
        setMessages((prevMessages) => [...prevMessages, message.data.data]);
      }

      if (chatCardBodyRef.current) {
        chatCardBodyRef.current.scrollTop = chatCardBodyRef.current.scrollHeight;
      }
    });

    socket.on(`${senderId}-${receiverId}-get-chat-history`, (response) => {
      console.log("Chat history received:", response);
      setIsChatHistoryLoading(false);
      if (response.result === "success") {
        setMessages(response.data);
      }
    });

    socket.on(`${senderId}-get-chat-threads`, (response) => {
      console.log("Chat threads received:", response);
      setIsChatThreadsLoading(false);
      if (response.result === "success") {
        setChatThreads(response.data.data);
      }
    });

    return () => {
      socket.off("connect", connectSocket);
      socket.off(`${senderId}-send-message`);
      socket.off(`${senderId}-${receiverId}-get-chat-history`);
      socket.off(`${senderId}-get-chat-threads`);
    };
  }, [senderId, receiverId]);

  // Send message
  const handleSendMessage = (event) => {
    event.preventDefault();
    if (newMessage) {
      const messageData = {
        sender_id: senderId,
        receiver_id: receiverId,
        message: newMessage,
      };
      console.log(messageData , 'message data')
      setIsSendingMessage(true);
      setMessages((prevMessages) => [...prevMessages, messageData]);
      setNewMessage('');
      socket.emit("send-message", messageData);
    }
  };

  // Handle thread click
  const handleThreadClick = (thread, threadId) => {
    setVisible(false);
    setReceiverId(thread);
    fetchChatHistory(senderId, thread);
    fetchUserData(thread);
    setActiveThreadId(threadId);
  };

  return (
    <>
      <Navbar authorized={true} />
      <section className='chat-screen-wrapper'>
        <CContainer>
          <div className='chat-screen-content'>
            <CRow>
              <CCol md={12}>
                <h2>{t('Your Messages')}</h2>
              </CCol>
              <CCol lg={4} className='mt-5'>
                <div className='edit-profile-sidebar'>
                  <CButton className="d-lg-none w-100 profile-sidebar-toggler" onClick={() => setVisible(true)}>
                    {t('Available Chats')} »
                  </CButton>
                  <COffcanvas responsive="lg" expand="lg" dark placement="start" visible={visible} onHide={() => setVisible(false)} backdrop={false}>
                    <COffcanvasHeader className='justify-content-end'>
                      <CCloseButton className="text-reset" onClick={() => setVisible(false)} />
                    </COffcanvasHeader>
                    <COffcanvasBody>
                      <div className="users-details-wrapper">
                        <div className='users-details-content'>
                          {chatThreads.map((thread) => {
                            const profileImage = thread.sender_id?._id === senderId
                              ? thread.receiver_id?.userDetail?.profile_image
                              : thread.sender_id?.userDetail?.profile_image;
                            const firstName = thread.sender_id?._id === senderId
                              ? thread.receiver_id?.first_name
                              : thread.sender_id?.first_name;
                            const sirName = thread.sender_id?._id === senderId
                              ? thread.receiver_id?.sir_name
                              : thread.sender_id?.sir_name;
                            const receiver = thread.sender_id?._id === senderId
                              ? thread.receiver_id?._id
                              : thread.sender_id?._id;

                            return (
                              <div key={thread._id} className={`single-user-detail-card mb-2 ps-2 ${activeThreadId === thread._id ? 'active' : ''}`} onClick={() => handleThreadClick(receiver, thread._id)}>
                                <CRow className='align-items-center h-100-p w-100 m-0'>
                                  <CCol lg={3} xs={3}>
                                    <div className="single-user-dp">
                                      {profileImage ? (
                                        <img src={`${imgeUrl}/${profileImage}`}
                                        onError={(e) => {
                                          e.target.src = avatar;
                                        }}
                                        alt="dp" />
                                      ) : (
                                        <img src={avatar} alt="dp" />
                                      )}
                                    </div>
                                  </CCol>
                                  <CCol lg={9} xs={9}>
                                    <div className="single-user-desc">
                                      <div className="single-user-name">{firstName} {sirName}</div>
                                      <div className="last-message">{thread.last_message}</div>
                                    </div>
                                  </CCol>
                                </CRow>
                              </div>
                            );
                          })}
                          {isChatThreadsLoading && <Spinner />}
                        </div>
                      </div>
                    </COffcanvasBody>
                  </COffcanvas>
                </div>
              </CCol>
              <CCol lg={8} className='mt-5'>
                <div className="users-chats-wrapper">
                  <div className="chat-card-header">
                    <div className="active-user-desc">{receiverName} <span className='active-dot'></span></div>
                  </div>
                  <div className="chat-card-body" ref={chatCardBodyRef}>
                    {isChatHistoryLoading ? (
                      <Spinner />
                    ) : (
                      <div className="chat-user">
                        {messages.map((msg, index) => (
                          <div key={index} className={msg.sender_id === senderId ? 'sender' : 'reciver'}>
                            {msg.sender_id === senderId ? (
                              <>
                                <div className={`sender-message-content me-2`}>
                                  {msg.message}
                                </div>
                                <div className='sender-img'>
                                  {senderImg && senderImg !== 'null' && senderImg !== null
                                    ? <img onError={(e) => {
                                      e.target.src = avatar;
                                    }} src={`${imgeUrl}/${senderImg}`} alt="user" />
                                    : <img src={avatar} alt="avatar" />}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='reciever-img me-2'>
                                  {receiverImg && receiverImg !== 'null'
                                    ? <img 
                                    onError={(e) => {
                                      e.target.src = avatar;
                                    }}
                                    src={`${imgeUrl}/${receiverImg}`} alt="user" />
                                    : <img src={avatar} alt="avatar" />}
                                </div>
                                <div className="reciever-message-content">
                                  {msg.message}
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                    <form>
                      <div className="typing-input-wrapper">
                        <input
                          type='text'
                          placeholder={t('Type here...')}
                          value={newMessage}
                          onChange={(e) => setNewMessage(e.target.value)}
                        />
                        <button type='submit' className='send-btn' disabled={!newMessage} onClick={handleSendMessage}>
                          {t('Send')}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </CCol>
            </CRow>
          </div>
        </CContainer>
      </section>
      <Footer />
      <CopyRight />
    </>
  );
}

export default UserChatScreen;
