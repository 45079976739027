import React from 'react';
import b1 from '../../assets/images/blogs/no-article.png';
import { useTranslation } from 'react-i18next';

function EmptyReleventBlog() {

    const { t } = useTranslation();

    return (
        <>
            <div className='relevent-blog-card-wrapper'>
                <div className='relevent-blog-img'>
                    <img src={b1} alt='blog'/>
                </div>
                <div className='relevent-blog-details mt-2'>
                    <h3>{t("Article Title")}</h3>
                    <p>
                        {t("coming soon")}...
                        {/* {t("Published on")}: 
                        <span className='mx-2'>Nov 11, 2024</span>
                        | <span className='ms-2'>23 min read</span> */}
                    </p>
                </div>
            </div>
        </>
    )
}

export default EmptyReleventBlog;