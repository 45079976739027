import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

function MediaCard({ imgSrc, mediaId, clsname, title, onDelete, mediaType, editProfile, setBlocking }) {
  const [hovered, setHovered] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const handleDelete = async () => {
    if (!mediaId) {
      console.error('No media ID provided');
      return;
    }

    setBlocking(true);

    let endpoint = '';
    if (mediaType === 'photo') {
      endpoint = `${apiUrl}/photo/${mediaId}`;
    } else if (mediaType === 'film') {
      endpoint = `${apiUrl}/film/${mediaId}`;
    } else {
      console.error('Invalid media type');
      return;
    }

    try {
      const language = localStorage.getItem("language");
      const token = localStorage.getItem('token');
      const response = await fetch(endpoint, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'accept-language': language
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        setBlocking(false);
        toast.error(errorData.message);
        throw new Error(`HTTP error! status: ${response.status}, Message: ${errorData.message}`);
      }

      const responseData = await response.json();

      onDelete && onDelete(mediaId);
      console.log(`${mediaType} deleted successfully`);
      setBlocking(false);
      toast.success(responseData.message);
    } catch (error) {
      setBlocking(false);
      console.error(`Failed to delete the ${mediaType}:`, error);
    }
    setBlocking(false);
  };

  return (
    <div
      className='media-card-wrapper'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className='media-img'>
        {hovered && editProfile &&(
          <button className='delete-icon' onClick={handleDelete}>
            <svg width="28" height="28" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M29 57.9619C45.0163 57.9619 58 44.9867 58 28.981C58 12.9752 45.0163 0 29 0C12.9837 0 0 12.9752 0 28.981C0 44.9867 12.9837 57.9619 29 57.9619Z" fill="#292929"/>
              <path d="M19.8035 15.2953L15.3186 19.7803L38.201 42.6627L42.686 38.1777L19.8035 15.2953Z" fill="white"/>
              <path d="M38.1978 15.2934L15.3154 38.1758L19.8004 42.6607L42.6828 19.7783L38.1978 15.2934Z" fill="white"/>
            </svg>
          </button>
        )}
        <img src={imgSrc} alt='Media' />
      </div>
      <div className={`media-caption ${clsname}`}>
        {title}
      </div>
    </div>
  );
}

export default MediaCard;
